import { Component, OnInit } from '@angular/core';
import { LibsService } from './libs.service';

@Component({
  selector: 'app-libraries',
  templateUrl: './libraries.component.html',
  styleUrls: ['./libraries.component.scss']
})
export class LibrariesComponent implements OnInit {
  libs: any[] = []; // Array to hold fetched libraries
  selectedLib: any = null; // Currently selected library for details
  searchName: string = ''; // Name to search by

  constructor(private libsService: LibsService) { }

  ngOnInit(): void {
    this.fetchLibs();
  }

  fetchLibs() {
    this.libsService.getLibs().subscribe({
      next: (data) => {
        this.libs = data.libs || [];
      },
      error: (error) => {
        console.error('Error fetching libraries:', error);
      }
    });
  }

  fetchLibById(libId: string) {
    this.libsService.getLibById(libId).subscribe({
      next: (lib) => {
        this.selectedLib = lib;
      },
      error: (error) => {
        console.error(`Error fetching library (${libId}):`, error);
      }
    });
  }

  createLib(libData: any) {
    this.libsService.createLib(libData).subscribe({
      next: () => {
        this.fetchLibs(); // Refresh the list after creation
      },
      error: (error) => {
        console.error('Error creating library:', error);
      }
    });
  }

  updateLib(libId: string, libData: any) {
    this.libsService.updateLib(libId, libData).subscribe({
      next: () => {
        this.fetchLibs(); // Refresh the list after updating
      },
      error: (error) => {
        console.error(`Error updating library (${libId}):`, error);
      }
    });
  }

  deleteLib(libId: string) {
    this.libsService.deleteLib(libId).subscribe({
      next: () => {
        this.fetchLibs(); // Refresh the list after deletion
      },
      error: (error) => {
        console.error(`Error deleting library (${libId}):`, error);
      }
    });
  }
}
