import { Component } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  selectedIcon: string = 'home'; // Default selected icon

  selectIcon(icon: string) {
    this.selectedIcon = icon;
  }

  isSelected(icon: string): boolean {
    return this.selectedIcon === icon;
  }
}
