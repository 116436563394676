<app-navbar></app-navbar>
<div *ngIf="isAuthenticated" class="main-layout">
  <app-sidebar></app-sidebar>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>
<div *ngIf="!isAuthenticated">
  <app-home></app-home>
</div>

