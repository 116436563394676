<div class="libs-container">
    <h2>Libraries</h2>
    <button mat-raised-button color="primary" (click)="fetchLibs()">Refresh Libraries</button>

    <div class="search-container">
        <mat-form-field appearance="fill">
            <mat-label>Search Library by Name</mat-label>
            <input matInput [(ngModel)]="searchName" placeholder="Enter library name" />
        </mat-form-field>
        <button mat-raised-button color="primary">Search</button>
    </div>

    <mat-list *ngIf="libs; else noLibs">
        <mat-list-item *ngFor="let lib of libs" (click)="fetchLibById(lib.id)">
            <div mat-line>{{ lib.name }}</div>
        </mat-list-item>
    </mat-list>

    <ng-template #noLibs>
        <p>No libraries found.</p>
    </ng-template>
</div>