import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  ChatService,
  HealthCheckResponse,
  HealthCheckResponseA,
} from '../../chat/chat.service';
import { ConfigService } from '../../../config.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-health-dialog',
  templateUrl: './health-dialog.component.html',
  styleUrls: ['./health-dialog.component.scss'],
})
export class HealthDialogComponent implements OnInit {
  healthResponseC!: any; // For API C response
  healthResponseA!: any; // For API A response
  healthResponseP!: any; // For API P response
  uiVer!: string; // UI version from config
  uiLastUpdatedate!: any ; // Initialize to null
  commit!: any;
  constructor(
    public chat: ChatService,
    public config: ConfigService,
    public http: HttpClient
  ) {
    
  }

  ngOnInit(): void {
    // Load UI version from configuration
    const version = this.config.loadConfig();
    version.subscribe((response: any) => {
      if (response) {
        this.uiVer = response.uiVersion;
      }
    });

    // Health Check for API C
    this.chat.healthCheck().subscribe((response: any) => {
      if (response) {
        this.healthResponseC = response;
       //console.log('HEALTHCHECK-C', response);
      }
    });

    // Health Check for API A
    this.chat.healthCheckA().subscribe((responseA: any) => {
      if (responseA) {
        this.healthResponseA = responseA;
       // console.log('HEALTHCHECK-A', responseA);
      }
    });

    // Health Check for API P
    this.chat.healthCheckP().subscribe((responseP: any) => {
      if (responseP) {
        this.healthResponseP = responseP;
       // console.log('HEALTHCHECK-P', responseP);
      }
    });
    this.fetchLastUpdate();
    this.fetchLastCommit()
  }
  fetchLastUpdate() {
    this.http.get('/date.txt', { responseType: 'text' })
      .subscribe({
        next: (response: string) => {
          const parsedDate = new Date(response.trim());
          // Check if the parsed date is valid
          if (!isNaN(parsedDate.getTime())) {
            this.uiLastUpdatedate = parsedDate;
            console.log(parsedDate)
          } else {
            console.error('Invalid date format received:', response);
          }
        },
        error: (error) => {
          console.error('Error fetching date:', error);
        }
      });
  }
  
  fetchLastCommit(){
    this.http.get('/version.txt', { responseType: 'text' })
      .subscribe({
        next: (response: string) => {
          this.commit = response;
        },
        error: (error) => {
          console.error('Error fetching date:', error);
        }
      });
  };


}
