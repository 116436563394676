import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  isAuthenticated = false;
  isAuthChecked = false;
  constructor(public auth: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.auth.isAuthenticated$.subscribe({
      next: (authStatus) => {
        this.isAuthenticated = authStatus;
        this.isAuthChecked = true;
        if (this.isAuthenticated) {
          this.router.navigate(['/']); // Redirect to a dashboard or main page if authenticated
        }
      },
      error: (err) => {
        console.error('Error checking authentication', err);
        this.isAuthChecked = true;
      }
    });
  }

  login(): void {
    this.auth.loginWithRedirect();
  }
}
