<p>confirm-dialog works!</p>
<h2 mat-dialog-title>
    {{ data.message }}
  </h2>
  
  <mat-dialog-actions align="end">
    <button mat-button (click)="onDismiss()">Cancel</button>
    <button
      mat-button
      style="background-color: #B4FFCF; color: #000;"
      (click)="onConfirm()"
    >
      {{ data.action }}
    </button>
  </mat-dialog-actions>
  