import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from './environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes('/assets')) {
      // Skip token addition for asset requests
      return next.handle(request);
    }
    const baseUrl = request.url.startsWith('/local') ?
    environment.api.chatApiAuth.host :
    environment.api.fabrikHub.host;


    return this.authService.getAccessTokenSilently().pipe(
      switchMap(token => {
        const authRequest = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json'
          },
          url: request.url.startsWith('http') ? request.url : `${baseUrl}${request.url}`
        });
        return next.handle(authRequest);
      }),
      catchError(err => throwError(() => err))
    );
  }
}
