import { Injectable, WritableSignal, computed, signal } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  catchError,
  forkJoin,
  map,
  throwError,
} from 'rxjs';
import { PointStyles } from 'scatter-gl/dist/styles';
import { Point3D, PointMetadata } from 'scatter-gl';
import { ConfigService } from '../../config.service';

export type Projection = [number, number, number];

// Define an interface for the response
export interface ChatApiResponse {
  totalCount: number;
  pageSize: number;
  pageNumber: number;
  items: ChatItem[];
}
export interface HealthCheckResponse {
  message: string;
  version: string;
  time: Date;
  info: string;
  profile: string;
  id: string;
}
export interface HealthCheckResponseA {
  message: string;
  version: string;
  time: Date;
  info: string;
  profile: string;
  id: string;
  status: string;
}
export interface AteneaData {
  response: string;
  fabric_ids: FabricID[];
  response_imgs: string[];
}
export interface FabricID {
  fabricID: string;
  side: null | string;
  articleCode: string;
}
export interface ChatItem {
  chatId: string;
  userId: string;
  title: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  version: number;
  messages: Message[];
}
export interface BaseMessage {
  chatId: string;
  messageId: string;
  editing?: boolean;
  origin: 'BOT' | 'USER';
  userId: string;
  isPrompt: boolean;
  isAnswer: boolean;
  isNew?: boolean; // Indicate if this is a new message in the current session.
  showAvatar?: boolean; // Control avatar display.
  showMessage?: boolean; // Control when to start the typewriter effect.
  delayStart?: number; // Delay before starting the typewriter effect.
  type: 'CHATGPT' | 'ATENEA' | '';
  timeStamp?: Date | string | undefined;
  showPlotBtn?: boolean;
  response_imgs?: string[];
}
export interface ChatGPTMessage extends BaseMessage {
  type: 'CHATGPT';
  data: string;
  imageBase64?: string;
}
export interface AteneaMessage extends BaseMessage {
  type: 'ATENEA';
  data: MessageDataStructure | string;
  imageBase64?: string;
  module?: string;
}
export interface TmpMessage extends BaseMessage {
  type: '';
  data: string;
  imageBase64?: string;
  response_imgs: string[];
  module?: string;
}
export type Message = ChatGPTMessage | AteneaMessage | TmpMessage;
export interface MessageDataStructure {
  response: string;
  fabric_ids?: FabricID[];
  response_imgs?: string[];
  module?: string;
}
export function isMessageDataStructure(
  data: MessageDataStructure | string,
): data is MessageDataStructure {
  return (data as MessageDataStructure).response !== undefined;
}

export interface Groups {
  descriptions: GroupItem[];
}

export interface GroupItem {
  groupid: number | string;
  description: string;
  extended_description: string | null;
  fabrics: Fabric[];
  subgroups: GroupItem[];
}

export type FabricImage = {
  url: string;
  label: string;
};

export interface Fabric {
  cost_dollars: number | null;
  density: number | null;
  finishingProcess: string | null;
  shrinkage: number | null;
  weaveType: string | null;
  fabricid: string;
  side: number | string;
  articleCode: string;
  supplierName: string | null;
  imageUrl: string | null;
  highlight: string | number | null;
  color: string | number | null;
  composition: string | null;
  //
  fabricPerUnit: null | number;
  totalFabric: null | number;
  optimalQuantity: null | number;
  costPerUnit: null | number;
  totalCost: null | number;
  // not used
  insideDescription: string | null;
  weight: string | null;
  tactile: string | null;
  quality: string | null;
  cool_warm: string | null;
  woven_knitted: string | null;
  thickness: string | null;
  style: string | null;
}

export interface PlotFabric extends Fabric {
  group: string;
}

const colorSelected = 'rgba(250, 102, 102, 0.7)';
const colorUnselected = 'rgba(227, 227, 227, 0.7)';
const colorNoSelection = 'rgba(117, 117, 217, 0.7)';
const colorHover = 'rgba(118, 11, 79, 0.7)';
const colorSelectedFull = 'rgba(250, 102, 102, 1)';
const searchSelected = 'rgba(25,135, 255, 1)';
const neighborColor = 'rgba(250, 102, 102, 0.5)';
const axeColor = '#000000';
const primaryColor = '#303030';

export type ColorMap = Omit<
  PointStyles & {
    neighborColor: string;
  },
  'scaleDefault' | 'scaleSelected' | 'scaleHover'
>;

export const colorMap: ColorMap = {
  colorUnselected,
  colorNoSelection,
  colorSelected,
  colorHover,
  neighborColor,
};

export type ColoredMetadata = PointMetadata & {
  color: string;
  hoverColor: string;
  image: string;
};
export interface Property {
  property: string;
  enabled: boolean;
}

export interface PropertiesResponse {
  properties: Property[];
}

export interface CollectionsResponse {
  collections: string[];
}
export type PlotData = {
  points: Point3D[];
  metadata: ColoredMetadata[];
};
export interface ChatDetails {
  id: string;
  title: string;
  userId: string;
  messages: Message[];
}
export type DescriptionItem = {
  groupId: string;
  description: string;
  extendedDescription: string;
  side: number;
  imageUrl: string;
  articleCode: string;
  supplierName: string;
};

export type MessagePlotData = Map<
  string,
  {
    projections: Projection[] | null;
    groups: GroupItem[] | null;
    mapped: Map<
      string,
      {
        projections: Projection;
      }
    > | null;
  }
>;

export type MappedFabrics = Map<
  string,
  {
    projections: Projection;
    fabric: FabricID;
    metadata?: Fabric & {
      group: number | string;
      groupLabel: string;
    };
  }
>;

export interface IChatService {
  currentChatPlotMessageData: WritableSignal<Map<
    string,
    {
      fabricIds: FabricID[];
      projections: Projection[];
      groups: GroupItem[];
      module: string;
      mapped: Map<
        string,
        {
          projections: Projection;
        }
      >;
    }
  > | null>;
  config: ConfigService;
  chatListUpdated$: Observable<boolean>;
  notifyChatListUpdate(): void;
  resetChatListUpdated(): void;

  getChats(
    pageNumber?: number,
    pageSize?: number,
    sortField?: string,
    sortDirection?: string,
  ): Observable<ChatApiResponse>;

  createEmptyChat(title: string): Observable<string>;

  getChatById(chatId: string): Observable<ChatItem>;

  deleteChat(id: string): Observable<any>;

  makePrompt(
    chatId: string,
    prompt: string,
    imageBase64?: string,
  ): Observable<any>;

  sendReaction(
    messageId: string,
    reaction: { isLiked: boolean; isDisliked: boolean },
  ): Observable<any>;

  healthCheck(): Observable<HealthCheckResponse>;

  updatePrompt(messageId: string, prompt: string): Observable<any>;

  fetchPlotData(cid: string, payload: FabricID[], module: string): void; // <-- Updated signature
}

@Injectable({
  providedIn: 'root',
})
export class ChatService implements IChatService {
  currentChatPlotMessageId = signal<string | null>(null);
  currentChatPlotMessageData = signal<Map<
    string,
    {
      fabricIds: FabricID[];
      projections: Projection[];
      groups: GroupItem[];
      module: string;
      mapped: MappedFabrics;
    }
  > | null>(null);

  hasCurrentPlot = computed(() => {
    const id = this.currentChatPlotMessageId();
    const map = this.currentChatPlotMessageData();
    if (id === null || map === null) {
      return false;
    }
    return Boolean(map.get(id));
  });

  currentPlotMessageId$ = new BehaviorSubject<string | null>(null);
  private plotDataSubject = new BehaviorSubject<{
    projections: Projection[] | null;
    groups: GroupItem[] | null;
    mapped: Map<
      string,
      {
        projections: Projection;
      }
    > | null;
  }>({ projections: null, groups: null, mapped: null });

  get plotData$() {
    return this.plotDataSubject.asObservable();
  }

  private currentChatMessages = new BehaviorSubject<Message[]>([]);
  get currentChatMessages$(): Observable<Message[]> {
    return this.currentChatMessages.asObservable();
  }
  private chatListUpdated = new BehaviorSubject<boolean>(false);
  get chatListUpdated$(): Observable<boolean> {
    return this.chatListUpdated.asObservable();
  }

  notifyChatListUpdate() {
    this.chatListUpdated.next(true);
  }
  resetChatListUpdated() {
    this.chatListUpdated.next(false);
  }
  constructor(public http: HttpClient, private configService: ConfigService) { this.loadConfig(); }
  config!: ConfigService;
  private apiConfig: any;
  loadConfig(): void {
    this.configService.loadConfig().subscribe({
      next: (config) => {
        this.apiConfig = config.api; // Load the API configuration from config.json
      },
      error: (error) => {
        console.error('Error loading config:', error);
      },
    });
  }
  getChats(
    pageNumber: number = 0,
    pageSize: number = 50,
    sortField: string = 'updatedAt',
    sortDirection: string = 'DESC',
  ): Observable<ChatApiResponse> {
    let params = new HttpParams()
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString())
      .set('sortField', sortField)
      .set('sortDirection', sortDirection);

    params = params.set('sortField', sortField);
    params = params.set('sortDirection', sortDirection);
    return this.http.get<ChatApiResponse>(
      `${this.apiConfig.api.chatApiAuth.host}/v1/chat`,
      {
        params,
      },
    );
  }


  createEmptyChat(title: string): Observable<string> {
    return this.http
      .post<{
        chatId: string;
      }>(`${this.apiConfig.chatApiAuth.host}/v1/chat`, { title })
      .pipe(
        map((response) => response.chatId), // Assuming the backend returns the chat ID in this format
      );
  }

  getChatById(chatId: string) {
    return this.http.get<ChatItem>(
      `${this.apiConfig.chatApiAuth.host}/v1/chat/${chatId}`,
    );
  }

  deleteChat(id: string) {
    return this.http.delete(
      `${this.apiConfig.api.chatApiAuth.host}/v1/chat/${id}`,
    );
  }
  makePrompt(chatId: string, prompt: string, imageBase64?: string): Observable<any> {
    // Ensure that the configuration is loaded
    if (!this.apiConfig || !this.apiConfig.chatApi || !this.apiConfig.chatApi.host) {
      return throwError(() => new Error('API config or chatApi host is not loaded.'));
    }

    const requestBody: any = {
      chatId,
      prompt,
    };

    return this.http
      .post<[Message, Message]>(`${this.apiConfig.chatApi.host}/v1/prompt`, requestBody)
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        }),
      );
  }


  sendReaction(messageId: string, reaction: { isLiked: boolean; isDisliked: boolean }): Observable<any> {
    // Check if apiConfig is loaded and chatApi is defined
    if (!this.apiConfig || !this.apiConfig.chatApi || !this.apiConfig.chatApi.host) {
      return throwError(() => new Error('API config or chatApi host is not loaded.'));
    }

    return this.http.post(
      `${this.apiConfig.chatApi.host}/v1/prompt/reaction/${messageId}`,
      reaction
    ).pipe(
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }


  healthCheck() {
    return this.http.get<HealthCheckResponse>(
      `${this.apiConfig.chatApi.host}/health_check`,
    );
  }
  healthCheckA() {
    return this.http.get<HealthCheckResponseA>(
      `${this.apiConfig.chatApiAuth.host}/actuator/health`,
    );
  }
  healthCheckP() {
    return this.http.get<HealthCheckResponse>(
      `${this.apiConfig.fabrikHub.host}/actuator/health`,
    );
  }

  updatePrompt(messageId: string, prompt: string) {
    return this.http.put(
      `${this.apiConfig.api.chatApi.host}/v1/prompt/${messageId}`,
      prompt,
    );
  }

  fetchFabricsDescription(payload: FabricID[], property: string) {
    return this.http.post<Groups>(
      `${this.apiConfig.chatApi.host}/v1/descriptions/get_descriptions`,
      {
        fabrics: payload,
        module: property,
      },
    );
  }

  fetchFabricsProjection(payload: FabricID[], property: string) {
    return this.http.post<{
      projections: Projection[];
    }>(`${this.apiConfig.chatApi.host}/v1/projections/get_projections`, {
      fabrics: payload,
      module: property,
    });
  }

  fetchPlotData(
    cid: string,
    payload: FabricID[],
    property: string,
  ): Subscription | undefined {
    const ctx = this;
    const currentMap = this.currentChatPlotMessageData();
    if (currentMap && currentMap.get(cid)) {
      this.currentChatPlotMessageId.set(cid);
      return;
    }

    return forkJoin({
      descriptions: ctx.fetchFabricsDescription(payload, property),
      projections: ctx.fetchFabricsProjection(payload, property),
    }).subscribe({
      next: (response) => {
        const mapped: MappedFabrics = new Map();
        payload.forEach((fabric, idx) => {
          mapped.set(`${fabric.fabricID}_${fabric.side}`, {
            projections: response.projections.projections[idx],
            fabric: fabric,
          });
        });
        if (currentMap) {
          this.currentChatPlotMessageData.set(
            currentMap.set(cid, {
              fabricIds: payload,
              projections: response.projections.projections,
              groups: response.descriptions.descriptions,
              module: property,
              mapped,
            }),
          );
        } else {
          this.currentChatPlotMessageData.set(
            new Map([
              [
                cid,
                {
                  fabricIds: payload,
                  projections: response.projections.projections,
                  groups: response.descriptions.descriptions,
                  module: property,
                  mapped,
                },
              ],
            ]),
          );
        }
        this.currentChatPlotMessageId.set(cid);
      },
      error: (error) => console.error('Error fetching data', error),
    });
  }
  getProperties(clientID: string): Observable<PropertiesResponse> {
    return this.http
      .post<PropertiesResponse>(
        `${this.apiConfig.chatApi.host}/v1/properties/get_properties`,
        { clientID },
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        }),
      );
  }
  getCollections(clientID: string): Observable<CollectionsResponse> {
    return this.http
      .post<CollectionsResponse>(
        `${this.apiConfig.chatApi.host}/v1/collections/get_collections`,
        { clientID },
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        }),
      );
  }
}
