import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from '../src/app/environments/environment';
import { enableProdMode } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient, HttpXhrBackend } from '@angular/common/http';

if (environment.production) {
  enableProdMode();
}

// Create HttpClient instance for ConfigService
const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));

// Initialize ConfigService
const configService = new ConfigService(httpClient);

// Load configuration before bootstrapping Angular application
configService.loadConfig().subscribe({
  next: (config:any) => {
    platformBrowserDynamic([{ provide: 'APP_CONFIG', useValue: config }])
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  },
  error: (err:any) => {
    console.error('Could not load configuration', err);
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  }
});
