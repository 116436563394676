import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { HealthDialogComponent } from '../../components/health-dialog/health-dialog.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {


  selectedIcon: string = 'apps'; // Default selected icon


  selectIcon(icon: string) {
    this.selectedIcon = icon;
  }

  isSelected(icon: string): boolean {
    return this.selectedIcon === icon;
  }

  isReloadDialogOpen = false;
  sensors: [] = [];
  langs: string[] | undefined;
  activeLang: string | null | undefined;
  connections: '' | null = null;
  isSensor = false;
  isMobile = false;
  selectedSensor!: any | null;
  id = '';
  label = '';
  status = '';
  sensorSubscription: Subscription = new Subscription();
  sensorTimerIntervalRef: any = null;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public auth: AuthService,
    public dialog: MatDialog,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getAllSensors();
    localStorage.getItem('sensorId');
  }

  addNewSensor() {
    // Add new sensor logic
  }

  openDialog() {
    this.dialog.open(HealthDialogComponent);
  }

  selectSensor(sensor: []) {
    this.selectedSensor = sensor;
    this.id = this.selectedSensor.sensorId;
    this.label = this.selectedSensor.label;
    localStorage.setItem('sensorId', this.id);
    localStorage.setItem('sensorLabel', this.selectedSensor.label);
    localStorage.setItem('sensorStatus', this.selectedSensor.status);
  }

  getAllSensors() {
    if (this.sensorSubscription) {
      this.sensorSubscription.unsubscribe();
    }
    // Get all sensors logic
  }

  logOut() {
    this.auth.logout({ logoutParams: { returnTo: document.location.origin } });
  }

  goToProfile() {
    this.router.navigate(['profile']);
  }
  goToLibs() {
    this.router.navigate(['libs']);
  }


}
