import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Point3D, PointMetadata } from 'scatter-gl';
import { PointStyles } from 'scatter-gl/dist/styles';
import { environment } from '../environments/environment';
import { Observable, map, tap } from 'rxjs';

const colorSelected = 'rgba(250, 102, 102, 0.7)';
const colorUnselected = 'rgba(227, 227, 227, 0.7)';
const colorNoSelection = 'rgba(117, 117, 217, 0.7)';
const colorHover = 'rgba(118, 11, 79, 0.7)';
const colorSelectedFull = 'rgba(250, 102, 102, 1)';
const searchSelected = 'rgba(25,135, 255, 1)';
const neighborColor = 'rgba(250, 102, 102, 0.5)';
const axeColor = '#000000';
const primaryColor = '#303030';

export type ColorMap = Omit<
  PointStyles & {
    neighborColor: string;
  },
  'scaleDefault' | 'scaleSelected' | 'scaleHover'
>;

export const colorMap: ColorMap = {
  colorUnselected,
  colorNoSelection,
  colorSelected,
  colorHover,
  neighborColor,
};

export type ColoredMetadata = PointMetadata & {
  color: string;
  hoverColor: string;
};

export type PlotData = {
  points: Point3D[];
  metadata: ColoredMetadata[];
};

export type Projection = [number, number, number];

export interface Fabric {
  id: string;
  fabricId: string;
  side: number;
  clusterId: number | null;
  createdAt: string;
  version: number;
  metadata: {
    articleCode: string;
    supplierName: string;
    composition: string | null;
    images: string[];
  };
  projection: Projection;
}

@Injectable({
  providedIn: 'root',
})
export class PlotService {
  constructor(private http: HttpClient) {}

  fetchPlot(id: string) {
    return this.http
    .get<Fabric[]>(`${environment.api.fabrikHub.host}/v1/plot/${id}`)
    .pipe(
        tap((response) => {
          console.log(response);
        }),
        map((fabrics) => {
          if (true) {
            return {
              points: [
                [7.783150672912598, 10.699580192565918, 6.143429279327393],
                [7.227686405181885, 14.039731979370117, 3.092937469482422],
                [5.487361907958984, 12.982627868652344, -0.7097371220588684],
                [-2.4263644218444824, 0.6159617304801941, 6.790606498718262],
                [-2.610590696334839, 0.4562993049621582, 6.777414798736572],
                [-2.5131587982177734, 0.8658394813537598, 5.979968070983887],
                [10.15555477142334, 8.303173065185547, 10.61386775970459],
                [10.403385162353516, 5.089134216308594, 10.81881046295166],
                [10.191771507263184, 6.147909164428711, 10.965229034423828],
              ] as Point3D[],
              metadata: [
                {
                  id: '609',
                  color: 'rgba(117, 117, 217, 0.7)',
                  hoverColor: 'rgba(118, 11, 79, 0.7)',
                  group: '11',
                },
                {
                  id: '608',
                  color: 'rgba(117, 117, 217, 0.7)',
                  hoverColor: 'rgba(118, 11, 79, 0.7)',
                  group: '11',
                },
                {
                  id: '607',
                  color: 'rgba(117, 117, 217, 0.7)',
                  hoverColor: 'rgba(118, 11, 79, 0.7)',
                  group: '11',
                },
                {
                  id: '1077',
                  color: 'rgba(117, 117, 217, 0.7)',
                  hoverColor: 'rgba(118, 11, 79, 0.7)',
                  group: '35',
                },
                {
                  id: '8c98e19e-b41d-4a58-b22d-0d16b837ede4',
                  color: 'rgba(117, 117, 217, 0.7)',
                  hoverColor: 'rgba(118, 11, 79, 0.7)',
                  group: '35',
                },
                {
                  id: '1078',
                  color: 'rgba(117, 117, 217, 0.7)',
                  hoverColor: 'rgba(118, 11, 79, 0.7)',
                  group: '35',
                },
                {
                  id: '1455',
                  color: 'rgba(117, 117, 217, 0.7)',
                  hoverColor: 'rgba(118, 11, 79, 0.7)',
                  group: '6',
                },
                {
                  id: '1457',
                  color: 'rgba(117, 117, 217, 0.7)',
                  hoverColor: 'rgba(118, 11, 79, 0.7)',
                  group: '6',
                },
                {
                  id: '1479',
                  color: 'rgba(117, 117, 217, 0.7)',
                  hoverColor: 'rgba(118, 11, 79, 0.7)',
                  group: '6',
                },
              ],
            };
          }
          if (fabrics.length === 0) {
            return null;
          }
          const data = fabrics.reduce<PlotData>(
            (accum, fabric) => {
              let color = colorMap.colorNoSelection;

              accum.points.push(fabric.projection);
              accum.metadata.push({
                id: fabric.id,
                clusterId: fabric.clusterId ?? -1,
                label: fabric.metadata.articleCode,
                image: fabric.metadata.images[0],
                color,
                hoverColor: colorMap.colorHover,
              });

              return accum;
            },
            {
              points: [],
              metadata: [],
            }
          );
          return data;
        })
      );
  }
}
