<div class="health">
    
    <div class="assistant" *ngIf="uiVer">
        <h4>Web</h4>
        <p>Version: {{this.uiVer}}</p>
        <p>Last updated: {{ uiLastUpdatedate | date: 'short' }}</p>
        <p>Commit: {{this.commit}}</p>
    </div>
    <div class="assistant" *ngIf="healthResponseC">
        <h4>local-api-a</h4>
        <p>Message: {{healthResponseC.message}}</p>
        <p>Version: {{healthResponseC.version}}</p>
        <p>Last updated: {{healthResponseC.time | date: 'short'}}</p>
        <p>Info: {{healthResponseC.info}}</p>
        <p>Profile: {{healthResponseC.profile}}</p>
        <p>Id: {{healthResponseC.id}}</p>
    </div>

    <div class="assistant" *ngIf="healthResponseA">
        <h4>local-api-c</h4>
        <p>Status: {{healthResponseA.status}}</p>
        <p>Disk: {{healthResponseA.components.diskSpace.status}}</p>
        <p>Mongo: {{healthResponseA.components.mongo.status}}</p>
        <p>Ping: {{healthResponseA.components.ping.status}}</p>
    </div>

    <div class="assistant" *ngIf="healthResponseP">
        <h4>local-api-p</h4>
        <p>Status: {{healthResponseP.status}}</p>
        <p>Disk: {{healthResponseP.components.diskSpace.status}}</p>
        <p>Mongo: {{healthResponseP.components.mongo.status}}</p>
        <p>Ping: {{healthResponseP.components.ping.status}}</p>
    </div>
</div>
