import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from, of, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { createAuth0Client, Auth0Client, GetTokenSilentlyOptions } from '@auth0/auth0-spa-js';
import { AuthService } from '@auth0/auth0-angular';
import { ConfigService } from '../../../config.service';

@Injectable({
    providedIn: 'root'
})
export class LibsService {
    private auth0Client: Auth0Client | undefined;
    private auth0ClientPromise: Promise<void> | null = null;

    constructor(private http: HttpClient, private configService: ConfigService, private auth: AuthService) {
        this.initializeAuth0Client();
    }

    private initializeAuth0Client(): void {
        if (!this.auth0ClientPromise) {
            const auth0Config = this.configService.libsAuth0Config;

            if (!auth0Config || !auth0Config.domain || !auth0Config.clientId) {
                throw new Error('Auth0 configuration is missing');
            }

            this.auth0ClientPromise = createAuth0Client({
                domain: auth0Config.domain,
                clientId: auth0Config.clientId,
            }).then(client => {
                this.auth0Client = client;
            });
        }
    }

    private getAuthHeaders(): Observable<HttpHeaders> {
        if (!this.auth0ClientPromise) {
            this.initializeAuth0Client();
        }

        return from(this.auth0ClientPromise!).pipe(
            switchMap(() =>
                from(this.auth0Client!.getTokenSilently({
                    audience: this.configService.libsAuth0Config.audience,
                    scope: 'read:libs write:libs'
                } as GetTokenSilentlyOptions)).pipe(
                    switchMap(token => {
                        const headers = new HttpHeaders()
                            .set('Authorization', `Bearer ${token}`)
                            .set('Content-Type', 'application/json');
                        return of(headers);
                    }),
                    catchError(error => {
                        if (error.error === 'consent_required') {
                            console.error('User consent is required. Redirecting for login with consent.');
                            this.auth.loginWithRedirect({
                                appState: { target: '/libs' },
                                authorizationParams: {
                                    audience: this.configService.libsAuth0Config.audience,
                                    scope: 'read:libs write:libs'
                                }
                            });
                        }
                        return throwError(() => new Error('Authorization error'));
                    })
                )
            )
        );
    }

    // Basic CRUD and additional methods for Libs

    getLibs(): Observable<any> {
        return this.getAuthHeaders().pipe(
            switchMap(headers => this.http.get<any>(`${this.configService.libsApiUrl}/v1/libs`, { headers })),
            catchError(error => {
                console.error('Error fetching all libraries:', error);
                return throwError(() => error);
            })
        );
    }

    createLib(libData: any): Observable<any> {
        return this.getAuthHeaders().pipe(
            switchMap(headers => this.http.post<any>(`${this.configService.libsApiUrl}/v1/libs`, libData, { headers })),
            catchError(error => {
                console.error('Error creating a new library:', error);
                return throwError(() => error);
            })
        );
    }

    updateLib(libId: string, libData: any): Observable<any> {
        return this.getAuthHeaders().pipe(
            switchMap(headers => this.http.put<any>(`${this.configService.libsApiUrl}/v1/libs/${libId}`, libData, { headers })),
            catchError(error => {
                console.error(`Error updating library (${libId}):`, error);
                return throwError(() => error);
            })
        );
    }

    deleteLib(libId: string): Observable<any> {
        return this.getAuthHeaders().pipe(
            switchMap(headers => this.http.delete<any>(`${this.configService.libsApiUrl}/v1/libs/${libId}`, { headers })),
            catchError(error => {
                console.error(`Error deleting library (${libId}):`, error);
                return throwError(() => error);
            })
        );
    }

    getLibById(libId: string): Observable<any> {
        return this.getAuthHeaders().pipe(
            switchMap(headers => this.http.get<any>(`${this.configService.libsApiUrl}/v1/libs/${libId}`, { headers })),
            catchError(error => {
                console.error(`Error fetching library (${libId}):`, error);
                return throwError(() => error);
            })
        );
    }

    fetchFabricProjections(libId: string): Observable<any> {
        return this.getAuthHeaders().pipe(
            switchMap(headers => this.http.get<any>(`${this.configService.libsApiUrl}/v1/libs/${libId}/fabric-projections`, { headers })),
            catchError(error => {
                console.error(`Error fetching fabric projections (${libId}):`, error);
                return throwError(() => error);
            })
        );
    }

    fetchFabricsOfLib(libId: string): Observable<any> {
        return this.getAuthHeaders().pipe(
            switchMap(headers => this.http.get<any>(`${this.configService.libsApiUrl}/v1/libs/${libId}/fabrics`, { headers })),
            catchError(error => {
                console.error(`Error fetching fabrics of library (${libId}):`, error);
                return throwError(() => error);
            })
        );
    }

    addFabricsToLib(libId: string, fabricsData: any): Observable<any> {
        return this.getAuthHeaders().pipe(
            switchMap(headers => this.http.put<any>(`${this.configService.libsApiUrl}/v1/libs/${libId}/fabrics`, fabricsData, { headers })),
            catchError(error => {
                console.error(`Error adding fabrics to library (${libId}):`, error);
                return throwError(() => error);
            })
        );
    }

    removeFabricsFromLib(libId: string): Observable<any> {
        return this.getAuthHeaders().pipe(
            switchMap(headers => this.http.delete<any>(`${this.configService.libsApiUrl}/v1/libs/${libId}/fabrics`, { headers })),
            catchError(error => {
                console.error(`Error removing fabrics from library (${libId}):`, error);
                return throwError(() => error);
            })
        );
    }

    moveFabricsToLib(libId: string, moveData: any): Observable<any> {
        return this.getAuthHeaders().pipe(
            switchMap(headers => this.http.put<any>(`${this.configService.libsApiUrl}/v1/libs/${libId}/fabrics/move`, moveData, { headers })),
            catchError(error => {
                console.error(`Error moving fabrics to library (${libId}):`, error);
                return throwError(() => error);
            })
        );
    }

    getClusterStatus(libId: string): Observable<any> {
        return this.getAuthHeaders().pipe(
            switchMap(headers => this.http.get<any>(`${this.configService.libsApiUrl}/v1/libs/${libId}/cluster/status`, { headers })),
            catchError(error => {
                console.error(`Error fetching cluster status (${libId}):`, error);
                return throwError(() => error);
            })
        );
    }
}
