import { env } from "process";
export const environment = {
  production: true,
  useMsw: false,

  auth0: {
    domain: 'senstile-portal2.eu.auth0.com', // Fallback if the environment variable is not set
    clientId: 'N9VKHjSUikgDanzax9yuRIE0NofPz5Kb',
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: 'https://api.senstile.io',
    },
    allowedList: [
      {
        uri: 'https://local.fabrikhub.net/*',
        tokenOptions: {
          authorizationParams: {
            audience: 'https://senstile-portal2.eu.auth0.com/api/v2/',
          },
        },
      },
      {
        uri: 'https://local-api-p.fabrikhub.net/*',
        tokenOptions: {
          authorizationParams: {
            audience: 'https://senstile-portal2.eu.auth0.com/api/v2/',
          },
        },
      },
      {
        uri: 'https://local-api-c.fabrikhub.net/*',
        tokenOptions: {
          authorizationParams: {
            audience: 'https://senstile-portal2.eu.auth0.com/api/v2/',
          },
        },
      },
      {
        uri: 'https://local-api-a.fabrikhub.net/*',
        tokenOptions: {
          authorizationParams: {
            audience: 'https://senstile-portal2.eu.auth0.com/api/v2/',
          },
        },
      },
      {
        uri: 'http://localhost:4200/*',
        tokenOptions: {
          authorizationParams: {
            audience: 'https://senstile-portal2.eu.auth0.com/api/v2/',
          },
        },
      },
      {
        uri: 'http://localhost:4201/*',
        tokenOptions: {
          authorizationParams: {
            audience: 'https://senstile-portal2.eu.auth0.com/api/v2/',
          },
        },
      },
      {
        uri: 'https://chat.fabrikhub.net/*',
        tokenOptions: {
          authorizationParams: {
            audience: 'https://senstile-portal2.eu.auth0.com/api/v2/',
          },
        },
      },
    ],
  },

  api: {
    fabrikHub: {
      host: '',
      tokenOptions: {
        audience: 'https://api.senstile.io',
      },
    },
    chatApiAuth: {
      host: '',
      tokenOptions: {
        audience: 'https://api.senstile.io',
      },
    },
    chatApi: {
      host: '',
      tokenOptions: {
        audience: 'https://api.senstile.io',
      },
      
    },
  },
};
