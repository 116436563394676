import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { LibrariesComponent } from './components/libraries/libraries.component';
import { AdminLogsComponent } from './admin-logs/admin-logs.component';
import { AdminLogsRoutingModule } from './admin-logs/admin-logs-routing.module';



const routes: Routes = [
  // Home page (chat module)
  {
    path: '',
    loadChildren: () => import('./chat/chat.module').then((m) => m.ChatModule),
  },
  {
    path: 'libs',
    component: LibrariesComponent,
  },
  {
    path: 'admin-logs',
    canActivate: [AuthGuard],
    loadChildren: () => import('./admin-logs/admin-logs.module').then(m => m.AdminLogsModule)
  },
  // Redirect any unknown routes to the home page
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
